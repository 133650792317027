import { I18n } from './i18n';
import { Config } from './types';
import resolveDomain from './resolve-domain';
import resolveStripe from './resolve-stripe';
import resolvePusher from './resolve-pusher';
import resolveClever from './resolve-clever';
import resolveVersion from './resolve-version';
import resolveSubdomain from './resolve-subdomain';
import resolveClasslink from './resolve-classlink';

const env = process.env.NODE_ENV;

const version = resolveVersion();
const domain = resolveDomain();
const subdomain = resolveSubdomain();
const stripe = resolveStripe(domain.isSandbox);
const pusher = resolvePusher(domain.isSandbox, env);
const classlink = resolveClasslink(domain.isSandbox);
const clever = resolveClever(domain.isSandbox);

const config: Config = {
    env,
    envIsProduction: env === 'production',
    envIsTest: env === 'test',
    mode: process.env.REACT_APP_MODE as string,
    version,
    domain: domain.domain,
    isSandbox: domain.isSandbox,
    subdomain: subdomain.subdomain,
    isStudentSubdomain: subdomain.isStudentSubdomain,
    dashboardSubdomain: process.env.REACT_APP_SUBDOMAIN_APP as string,
    studentSubdomain: process.env.REACT_APP_SUBDOMAIN_STUDENT as string,
    programs: {
        cv: {
            title: process.env.REACT_APP_TITLE_CV as string,
        },
        academy: {
            title: process.env.REACT_APP_TITLE_ACADEMY as string,
        },
        zoou: {
            title: process.env.REACT_APP_TITLE_ZOOU as string,
        },
        ssgrin: {
            title: process.env.REACT_APP_TITLE_SSGRIN as string,
        },
        hoh: {
            title: process.env.REACT_APP_TITLE_HOH as string,
        },
        sim: {
            title: process.env.REACT_APP_TITLE_SIM as string,
        },
    },
    i18n: process.env.REACT_APP_I18N as I18n,
    marketingWebsiteDomain: process.env.REACT_APP_MARKETING_WEBSITE_DOMAIN as string,
    stripePublishableKey: stripe,
    pusher: {
        appKey: pusher,
        cluster: 'us2',
    },
    sentryDSN: {
        app: process.env.REACT_APP_SENTRY_REACT_DSN as string,
        cv: process.env.REACT_APP_SENTRY_CV_DSN as string,
        academy: process.env.REACT_APP_SENTRY_ACADEMY_DSN as string,
        zoou: process.env.REACT_APP_SENTRY_ZOOU_DSN as string,
        ssgrin: process.env.REACT_APP_SENTRY_SSGRIN_DSN as string,
        hoh: process.env.REACT_APP_SENTRY_HOH_DSN as string,
        sim: process.env.REACT_APP_SENTRY_SIM_DSN as string,
    },
    liveChatLicense: parseInt(process.env.REACT_APP_LIVE_CHAT_LICENSE as string),
    allowHandshakeIfUserNotActiveBySeconds: 60 * 30,
    momentDateFormat: process.env.REACT_APP_MOMENT_DATE_FORMAT as string,
    databaseDateFormat: 'YYYY-MM-DD',
    tabulator: {
        forceFreshFetchAfter: 60 * 5,
        filterDelay: 300,
        filterDateValidationFormat: process.env.REACT_APP_TABULATOR_FILTER_DATE_VALIDATION_FORMAT as string,
    },
    autocomplete: {
        searchDelay: 150,
    },
    snackbars: {
        maximumSnacksOnPage: 3,
        autoHideDuration: 8 * 1000,
    },
    ecommerce: {
        currency: 'USD',
        amounts: {
            individual: {
                50: 900,
                20: 1000,
                1: 1200,
            },
            site: {
                2000: 500,
                1000: 500,
                500: 500,
                250: 500,
                100: 600,
                50: 800,
            },
            tierUpgradePrice: 500,
        },
    },
    checkpoint: {
        version: '2.1.0',
        cvaVersion: '2.1.0',
    },
    classlink: {
        authUrl: 'https://launchpad.classlink.com/oauth2/v2/auth',
        clientId: classlink.clientId,
        redirectDomain: classlink.redirectDomain,
    },
    clever: {
        authUrl: 'https://clever.com/oauth/authorize',
        clientId: clever.clientId,
        redirectDomain: clever.redirectDomain,
    },
};

export const mode = {
    isCentervention: (): boolean => config.mode === 'centervention',
    isTaiwan: (): boolean => config.mode === 'taiwan',
    isBuildSnapshot: (): boolean => config.envIsProduction
        && window.location.protocol === 'http:'
        && window.location.hostname === 'localhost'
        && parseInt(window.location.port) > 8888,
};

export default config;
