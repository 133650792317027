import types, {
    UISignOutAction,
    TabsIdentifiers,
    ConfettiShowAction,
    SidebarToggleAction,
    TabsActivateTabAction,
    TabsActivateGroupAction,
} from './types';

const sidebarCollapseToggle = (): SidebarToggleAction => ({
    type: types.SIDEBAR_TOGGLE,
    payload: 'isCollapsed',
});

const sidebarUberToggle = (): SidebarToggleAction => ({
    type: types.SIDEBAR_TOGGLE,
    payload: 'uberIsOpen',
});

const sidebarStudentsToggle = (): SidebarToggleAction => ({
    type: types.SIDEBAR_TOGGLE,
    payload: 'studentsIsOpen',
});

const sidebarAccountSettingsToggle = (): SidebarToggleAction => ({
    type: types.SIDEBAR_TOGGLE,
    payload: 'accountSettingsIsOpen',
});

const uberToolsToggle = (): SidebarToggleAction => ({
    type: types.SIDEBAR_TOGGLE,
    payload: 'uberToolsIsOpen',
});

const tabsActivateGroup = (id: TabsIdentifiers, group: string, defaultTab: string): TabsActivateGroupAction => ({
    type: types.TABS_ACTIVATE_GROUP,
    payload: { id, group, defaultTab },
});

const tabsActivateTab = (id: TabsIdentifiers, group: string, tab: string): TabsActivateTabAction => ({
    type: types.TABS_ACTIVATE_TAB,
    payload: { id, group, tab },
});

const signOut = (accessToken: any, user: any): UISignOutAction => ({
    type: types.SIGN_OUT,
    payload: { accessToken, user },
});

const confettiShow = (show: boolean): ConfettiShowAction => ({
    type: types.CONFETTI_SHOW,
    payload: { show },
});

export default {
    sidebarCollapseToggle,
    sidebarUberToggle,
    sidebarStudentsToggle,
    sidebarAccountSettingsToggle,
    uberToolsToggle,
    tabsActivateGroup,
    tabsActivateTab,
    signOut,
    confettiShow,
};
