import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import Card from '../../components/layout/Card';
import { User } from '../../../state/ducks/auth';
import styles from './_welcome.module.sass';

type Props = {
    user: User,
}

const CardNiceToSeeYouBack: FunctionComponent<Props> = ({ user }) => (
    <Card classes={styles.card}>
        <FormattedMessage id="app.welcome-back.line1" values={{ name: user.firstName || user.username }} /><br/>
        <FormattedMessage id="app.welcome-back.line2"/>
    </Card>
);

export default CardNiceToSeeYouBack;
