import program from './program';
import { EducatorDocs } from '../../views/components/actions/educator/resources/types';
import { ParentResources } from '../../views/components/actions/parent/resources/types';

let modal = {
    'modal.header.login': 'Login',
    'modal.header.update-profile': 'Update Profile',
    'modal.header.change-my-password': 'Change My Password',
    'modal.header.class-code-manage': 'Manage Class Code',
    'modal.header.class-code-delete': 'Delete Class Code',
    'modal.header.purchase-history': 'Purchase History',
    'modal.header.district-add': 'Add District',
    'modal.header.district-edit': 'Edit District',
    'modal.header.district-delete': 'Delete District',
    'modal.header.school-add': 'Add School',
    'modal.header.school-edit': 'Edit School',
    'modal.header.school-delete': 'Delete School',
    'modal.header.team-add': 'Add Group',
    'modal.header.team-edit': 'Edit Group',
    'modal.header.team-delete': 'Delete Group',
    'modal.header.admins-of-team': 'Admins of Group',
    'modal.header.members-of-team': 'Members of Group',
    'modal.header.site-licenses-of-team': 'Site Licenses of Group',
    'modal.header.site-license-history': 'Site License History',
    'modal.header.educator-add': 'Add Educator',
    'modal.header.educator-create-account': 'Create Educator Account',
    'modal.header.educator-edit': 'Edit Educator',
    'modal.header.purchase-order-individual-add': 'Add Individual PO',
    'modal.header.purchase-order-site-add': 'Add Site PO',
    'modal.header.purchase-order-site-upgrade': 'Upgrade Site PO',
    'modal.header.parent-edit': 'Edit Parent',
    'modal.header.student-add': 'Add Student',
    'modal.header.student-create-account': 'Create Student Account',
    'modal.header.current-roster-count': 'Current Roster Count',
    'modal.header.is-active': '{program} is currently active',
    'modal.header.licenses-available': 'Licenses Available: {individual}',
    'modal.header.individual-licenses-available': 'Individual Program Licenses Available: {individual}',
    'modal.header.rollback-licenses-available': 'Reclaimable Individual Program Licenses: {individual}',
    'modal.header.student-login-page': 'Student Login Information',
    'modal.header.free-trial': 'Free Trial',
    'modal.header.child-create-account': 'Create New Student Account',
    'modal.header.child-create-account.sub-header-free-trial': 'Free Trial',
    'modal.header.child-create-account.sub-header-individual': 'Unused Licenses: {individual}',
    'modal.header.student-edit': 'Edit Student',
    'modal.header.child-edit': 'Edit Student',
    'modal.header.my-student-edit': 'Edit Student',
    'modal.header.all-student-edit': 'Edit Student',
    'modal.header.student-archive': 'Archive Student',
    'modal.header.student-unarchive': 'Unarchive Student',
    'modal.header.student-delete': 'Delete Student',
    'modal.header.student-bulk-upload': 'Students Bulk Upload',
    'modal.header.student-game-debug-mode': 'Game Debug Mode',
    'modal.header.student-clone-into-sandbox': 'Clone Into Sandbox',
    'modal.header.account-source-resend': 'Resend Activation Link',
    'modal.header.account-source-verify': 'Verify Account',
    'modal.header.account-source-hold': 'Put Verification On Hold',
    'modal.header.account-source-delete': 'Delete Not Activated Account',
    'modal.header.educators-of-school': 'Admins of School',
    'modal.header.educators-of-student': 'Educators of Student',
    'modal.header.parents-of-child': 'Parents of Student',
    'modal.header.schools-of-educator': 'Schools of Educator',
    'modal.header.teams-of-educator': 'Groups of Educator',
    'modal.header.students-valid-license-of-educator': 'Active Students of Educator',
    'modal.header.students-active-of-educator': 'Current Roster of Educator',
    'modal.header.students-archived-of-educator': 'Archived Students of Educator',
    'modal.header.children-active-of-parent': 'Current Roster of Parent',
    'modal.header.children-archived-of-parent': 'Archived Students of Parent',
    'modal.header.connect-students-with-educator': 'Manage My Students of Educator',
    'modal.header.transfer-students-list': 'Share or Transfer Students of Educator',
    'modal.header.transfer-students': 'Transfer Students',
    'modal.header.share-or-transfer-students': 'Share or Transfer Students',
    'modal.header.change-password': 'Change Password',
    'modal.header.reset-my-password': 'Reset Password',
    'modal.header.set-new-password': 'Set New Password',
    'modal.header.set-new-profile-username': 'Update Centervention Account Username',
    'modal.header.request-centervention-educator-account': 'Request Centervention Account',
    'modal.header.activate-centervention-educator-account': 'Create Password',
    'modal.header.demo': 'Game Preview',
    'modal.header.assign-program-free-trial': 'Assign Program with Free Trial',
    'modal.header.assign-program-individual': 'Assign Program with License',
    'modal.header.mass-archive': 'Archive Students',
    'modal.header.mass-unarchive': 'Unarchive Students',
    'modal.header.mass-students-selected': 'Students Selected: {selected}',
    'modal.header.mass-grade': 'Change Students Grade',
    'modal.header.mass-teacher': 'Change Students Teacher',
    'modal.header.mass-password': 'Change Students Password',
    'modal.header.mass-reset-password': 'Reset Students Password',
    'modal.header.mass-all-programs-report': 'Download Reports',
    'modal.header.mass-academy-report': 'Download Report',
    'modal.header.mass-zoou-assessment': 'Download Assessment Report',
    'modal.header.mass-zoou-skill-builder': 'Download Skill Builder Report',
    'modal.header.mass-ssgrin-report': 'Download Report',
    'modal.header.mass-hoh-assessment': 'Download Assessment Report',
    'modal.header.mass-hoh-skill-builder': 'Download Skill Builder Report',
    'modal.header.mass-restart-game': 'Restart Game',
    'modal.header.mass-restart-active-game': 'Restart Active Game',
    'modal.header.mass-unlock-assessment': `Unlock New ${program['program.zoou.title']} Assessment`,
    'modal.header.mass-transfer-by-admin': 'Transfer Students to Another School or Educator',
    'modal.header.mass-transfer-by-admin-select-educator': 'Transfer Students to Another School or Educator',
    'modal.header.mass-transfer-by-admin-select-educator.sub': 'Select Educator and Continue to Student List',
    'modal.header.mass-transfer-by-admin-all-students': 'Transfer Students to Another School or Educator',
    'modal.header.mass-transfer-students.sub': 'Select Students and Continue to Share or Transfer',
    'modal.header.mass-assign-license': 'Activate/Extend License',
    'modal.header.purchase-licenses': 'Purchase Licenses',
    'modal.header.purchase-license': 'Purchase License',
    'modal.header.purchase-new-student': 'Purchase New License',
    'modal.header.purchase-licenses-title-1': 'Purchase Individual Program Licenses',
    'modal.header.purchase-licenses-title-2': 'For Site License purchasing options, see our {url}',
    'modal.header.purchase-licenses-title-2-utl': 'website',
    'modal.header.select-student': 'Select Student',
    'modal.header.quote-create': 'Create Quote',
    'modal.header.quote-view': 'Quote',
    'modal.header.purchase-site-edit': 'Edit Purchase',
    'modal.header.last-request-quote-of-educator-view': 'Last Self Quote',
    'modal.header.last-quote-of-educator-view': 'Last Uber Quote',
    'modal.header.quote-of-history-view': 'Uber Quote',
    'modal.header.request-quote-of-history-view': 'Self Quote',
    'modal.header.request-quote-add': 'Request Price Quote',
    'modal.header.request-quote-view': 'Download Price Quote',
    'modal.header.invite-colleague': 'Invite a Friend',
    'modal.header.transfer-licenses': 'Transfer Licenses',
    'modal.header.reclaim-licenses': 'Reclaim Licenses',
    'modal.header.academy-report': `${program['program.academy.title']} Reports`,
    'modal.header.zoou-skill-builder': `${program['program.zoou.title']} Reports`,
    'modal.header.ssgrin-report': `${program['program.ssgrin.title']} Reports`,
    'modal.header.hoh-skill-builder': `${program['program.hoh.title']} Reports`,
    'modal.header.sim-library': `${program['program.sim.title']} Reports`,
    'modal.header.sim-comic': 'Comic',
    'modal.header.activate-program': 'Activate License',
    'modal.header.switch-program': 'Switch Program',
    'modal.header.renew-program': 'Extend License',
    'modal.header.purchase-new-parent': 'Parents and Homeschool Educators',
    'modal.header.parent-convert-to-educator': 'Convert Parent Account to Educator Account',
    'modal.header.educator-convert-to-parent': 'Convert Educator Account to Parent Account',
    'modal.header.assign-program-rollback': 'Rollback Assigned License',
    'modal.header.student-assign-program-history': 'License History',
    'modal.header.ecommerce-history': 'Ecommerce History',
    'modal.header.metrics-educator-access': 'Last 30 Days Access',
    'modal.header.metrics-admin-access': 'Last 30 Days Access',
    'modal.header.metrics-live-admin-metrics': 'Live Admin Metrics',
    'modal.header.account-deactivate': 'Deactivate Account',
    'modal.header.account-rollback-deactivate': 'Rollback Deactivate',
    'modal.header.educator-deactivate': 'Deactivate Educator',
    'modal.header.download-spreadsheet': 'Download Spreadsheet',
    'modal.header.schools-of-log': 'Affected Schools of Activity',
    'modal.header.teams-of-log': 'Affected Groups of Activity',
    'modal.header.educators-of-log': 'Affected Educators of Activity',
    'modal.header.students-of-log': 'Affected Students of Activity',
    'modal.header.reached-out': 'Reached Out',
    'modal.header.high-priority': 'High Priority | {username}',
    'modal.header.educator-schools': 'Schools',
    'modal.header.lesson-add': 'Add Lesson',
    'modal.header.lesson-edit': 'Edit Lesson',
    'modal.header.site-license.grant': 'Grant site license access',
    'modal.header.site-license.revoke': 'Revoke site license access',
    'modal.header.site-license-usage-report': 'Usage Report',

    [`modal.header.resources.${ParentResources.AcademyGuide}`]: `${program['program.academy.title']} {br} Guide`,
    [`modal.header.resources.${ParentResources.ZoouGuide}`]: `${program['program.zoou.title']} {br} Guide`,
    [`modal.header.resources.${ParentResources.SsgrinGuide}`]: `${program['program.ssgrin.title']} {br} Guide`,
    [`modal.header.resources.${ParentResources.HohGuide}`]: `${program['program.hoh.title']} {br} Guide`,
    [`modal.header.resources.${ParentResources.SimGuide}`]: `${program['program.sim.title']} {br} Guide`,

    'modal.body.account-source-resend': 'Resend activation link to {username}, are you sure?',
    'modal.body.account-source-hold': 'Put {username}\'s account verification on hold, are you sure?',
    'modal.body.account-source-delete': 'Delete {username}\'s not activated account, are you sure?',
    'modal.body.account-deactivate': `No longer allow {username} to use this account
        or create a new one, are you sure?`,
    'modal.body.archive': 'Archive {username}, are you sure?',
    'modal.body.unarchive': 'Unarchive {username}, are you sure?',
    'modal.body.confirm-assign-program-rollback': `Rollback current assigned license, are you sure?`,
    'modal.body.are-you-sure': 'Are you sure?',
    'modal.body.delete': 'Delete {item}, are you sure?',
    'modal.body.demo': `Game preview is only for educators to explore the game.
        Your student should log in at {link} {br}
        Their username is listed in the Manage Students section of your educator dashboard,
        and their password is the same as their username unless you changed it.`,
    'modal.body.student-login-page': `This is where your students will login and use Centervention programs: {br}
        {link} {br}
        Their username is listed in the Manage Students section of your educator dashboard,
        and their password is the same as their username unless you changed it.`,
    'modal.body.mass-report.empty': 'None of the selected students has a report available',
    'modal.body.mass-report.partial': `{reports} of {selected} selected students have a report available,
        {rest} will be skipped`,
    'modal.body.mass-report.all': `All selected students have report available,
        download all reports`,
    'modal.body.mass-restart-game': `Are you sure you want to restart the game for
        {selected} {selected, plural, one {student} other {students}}?
        The game state of the {selected, plural, one {student} other {students}} will be reset
        and {selected, plural, one {student} other {students}} will play the game from scratch.
        The restart can not be undone.`,
    'modal.body.mass-unlock-assessment': `Are you sure you want to unlock a new assessment for
        {selected} {selected, plural, one {student} other {students}}?
        The {selected, plural, one {student} other {students}} will need to complete the assessment
        before continuing the skill builder scenes.`,
    'modal.body.assign-program-rollback': `Since this student has not used the assigned license,
        you can 'rollback' the license, and it will be returned to your account to reuse.`,
    'modal.body.educator-convert-to-parent': `Convert {username}'s educator account
        to parent account, are you sure?`,
    'modal.body.mass-assign-license': `Activate/Extend {selected} {selected, plural, one {license} other {licenses}},
        are you sure?`,
    'modal.body.mass-assign-license.has-not-enough-licenses': `Before you can continue,
        make sure number of students selected
        is equal or lower than number of your licenses available`,
    'modal.body.mass-unlock-assessment.does-not-have-only-zoou-selected': `Before you can continue,
        make sure ${program['program.zoou.title']} is an Active Program for all selected students`,
    'modal.body.class-code.title': 'Class Code',
    'modal.body.class-code': `A {title} allows all of your students
        to login and play Centervention programs using a shared, common password.
        Note: Individual student passwords remain valid.`,
    'modal.body.clone-into-sandbox': `Clone {username} into Sandbox, are yor sure?`,
    'modal.body.game-debug-mode-on': `Do you want to turn OFF game debug mode for {username}?`,
    'modal.body.game-debug-mode-off': `Do you want to turn ON game debug mode for {username}?`,
    'modal.body.download-spreadsheet': `Download {rows} {rows, plural, one {row} other {rows}}, are yor sure?`,
    'modal.body.missing-quote': `Quote is not available`,
    'modal.body.educator-deactivate.warning': `Educator has remaining students.
        Transfer them first to another educator to continue.`,
    'modal.body.educator-deactivate.ready': `Are you sure you want to detach educator from all your schools / groups?`,
    'modal.body.reached-out': `Are you sure you want to mark {username} as reached out today?`,
    'modal.body.site-license.grant': `Grant site license access to {username}, are you sure?`,
    'modal.body.site-license.revoke': `Revoke site license access from {username}, are you sure?`,
    'modal.body.site-license.revoke.warning': `By revoking access to the site license for {username},
        they will also be removed from your list of educators. Are you sure?`,

    'modal.footer.students-added': 'Students Added',
    'modal.footer.students-removed': 'Students Removed',
    'modal.footer.students-connected': 'Students Connected',
    'modal.footer.metrics-live-report.schools': `List of Schools ({count})`,
    'modal.footer.metrics-live-report.schools.included': 'Filter Live Metrics by Schools ({count})',
    'modal.footer.metrics-live-report.educators': `List of Educators ({count})`,
    'modal.footer.metrics-live-report.educators.included': 'Filter Live Metrics by Educators ({count})',

    'modal.file-name.quote': 'Centervention Price Quote',

    'modal.button.send': 'Send',
    'modal.button.convert': 'Convert',
    'modal.button.put-on-hold': 'Put On Hold',
    'modal.button.deactivate': 'Deactivate',
    'modal.button.delete': 'Delete',
    'modal.button.cancel': 'Cancel',
    'modal.button.archive': 'Archive',
    'modal.button.unarchive': 'Unarchive',
    'modal.button.rollback': 'Rollback',
    'modal.button.reset': 'Reset',
    'modal.button.submit': 'Submit',
    'modal.button.login': 'Log in',
    'modal.button.submit.reset-password': 'Reset Password',
    'modal.button.open-demo': 'Open Game Preview',
    'modal.button.open-student-login-page': 'Open Student Login Page',
    'modal.button.close-demo': 'Close Game Preview',
    'modal.button.back': 'Back',
    'modal.button.assign-program': 'Assign Program',
    'modal.button.request-account': 'Request Account',
    'modal.button.transfer-licenses': 'Transfer',
    'modal.button.rollback-licenses': 'Reclaim',
    'modal.button.ok': 'OK',
    'modal.button.next': 'Next',
    'modal.button.continue': 'Continue',
    'modal.button.submit.next': 'Submit & Next',
    'modal.button.download': 'Download',
    'modal.button.restart': 'Restart',
    'modal.button.unlock': 'Unlock',
    'modal.button.activate': 'Activate',
    'modal.button.switch': 'Switch',
    'modal.button.mass-assign-license': 'Activate/Extend',
    'modal.button.renew': 'Extend',
    'modal.button.buy-now': 'Buy Now',
    'modal.button.request-quote-add': 'Request Price Quote',
    'modal.button.transfer-students': 'Transfer Students',
    'modal.button.grant-access': 'Grant Access',
    'modal.button.revoke-access': 'Revoke Access',
    'modal.button.revoke-access.warning': 'Revoke Access and remove educator from my list',

    'modal.add.last.id': 'Last added has id: {id}',
    'modal.download.name.reports': '{program} Reports',
    'modal.download.name.all-programs-report': 'Reports',
};

modal = { ...modal, ...{
    'modal.header.educators-of-student-active': modal['modal.header.educators-of-student'],
    'modal.header.educators-of-student-archived': modal['modal.header.educators-of-student'],
    'modal.header.parents-of-child-active': modal['modal.header.parents-of-child'],
    'modal.header.parents-of-child-archived': modal['modal.header.parents-of-child'],
    'modal.header.academy-time-played': modal['modal.header.academy-report'],
    'modal.header.zoou-assessment': modal['modal.header.zoou-skill-builder'],
    'modal.header.zoou-skill-builder-board': modal['modal.header.zoou-skill-builder'],
    'modal.header.zoou-time-played': modal['modal.header.zoou-skill-builder'],
    'modal.header.ssgrin-time-played': modal['modal.header.ssgrin-report'],
    'modal.header.hoh-assessment': modal['modal.header.hoh-skill-builder'],
    'modal.header.hoh-time-played': modal['modal.header.hoh-skill-builder'],
    'modal.header.sim-time-played': modal['modal.header.sim-library'],
    'modal.header.quote-add': modal['modal.header.quote-create'],
    'modal.header.quote-add-renewal': modal['modal.header.quote-create'],
    'modal.header.student-bulk-upload-rows': modal['modal.header.student-bulk-upload'],
    'modal.header.student-bulk-upload-pending': modal['modal.header.student-bulk-upload'],
    'modal.header.student-bulk-upload-created': modal['modal.header.student-bulk-upload'],
    'modal.header.student-bulk-upload-updated': modal['modal.header.student-bulk-upload'],
    'modal.header.student-bulk-upload-failed': modal['modal.header.student-bulk-upload'],
    'modal.header.student-bulk-upload-out-of-license': modal['modal.header.student-bulk-upload'],
    [`modal.header.resources.${EducatorDocs.QuickStartGuide}`]: 'Quick Start Guide',
    [`modal.header.resources.${EducatorDocs.StudentLoginCards}`]: 'Student Login Cards',
    [`modal.header.resources.${EducatorDocs.BackToSchoolTips}`]: 'Back to School Tips',
    [`modal.header.resources.${EducatorDocs.AcademyGuide}`]: `${program['program.academy.title']} Program Guide`,
    [`modal.header.resources.${EducatorDocs.ZoouGuide}`]: `${program['program.zoou.title']} Program Guide`,
    [`modal.header.resources.${EducatorDocs.SsgrinGuide}`]: `${program['program.ssgrin.title']} Program Guide`,
    [`modal.header.resources.${EducatorDocs.HohGuide}`]: `${program['program.hoh.title']} Program Guide`,
    [`modal.header.resources.${EducatorDocs.SimGuide}`]: `${program['program.sim.title']} Program Guide`,
    [`modal.header.resources.${EducatorDocs.CDCAndECIGuide}`]: 'Cool Down Corner and Emotion Check In Guide',
    [`modal.header.resources.${EducatorDocs.EducatorTrainingGuide}`]: 'Educator Training Guide',
    [`modal.header.resources.${EducatorDocs.EducatorTrainingVideo}`]: 'Educator Training Video',
    [`modal.header.resources.${EducatorDocs.ParentLetterAndPermissionSlip}`]: 'Parent Letter and Permission Slip',
}};

export default modal;
