import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormValues } from './types';
import validation from './validation';
import FormPage from '../../../form/FormPage';
import LinkButton from '../../../common/LinkButton';
import AccountRequestForm from './AccountRequestForm';
import Notification from '../../../common/Notification';
import withFormPageDefault from '../../../hoc/withFormPageDefault';
import { FormDefaultProps, FormPageProps, FormProps } from '../../../form/types';
import { endpoints, PageResponder, PageResponderNotification } from '../../../../../api';

class AccountRequest extends React.Component<FormDefaultProps<FormValues> & RouteComponentProps> {
    notification(pageResponder: PageResponder<PageResponderNotification>) {
        const actions = pageResponder.data.i18n === 'is-activated' ? <LinkButton i18n="login"/> : undefined;

        return <Notification type={pageResponder.data.type} actions={actions}>
            <FormattedMessage id={`notification.on-page.${pageResponder.data.i18n}`}/>
        </Notification>;
    }

    render() {
        const { pageResponder, location } = this.props;

        if (pageResponder.received) {
            return this.notification(pageResponder as PageResponder<PageResponderNotification>);
        }

        const urlSearchParams = new URLSearchParams(location.search);

        const pageProps: FormPageProps = {
            headerI18n: 'request-centervention-educator-account',
        };

        const formProps: FormProps<FormValues> = {
            renderForm: (props) => <AccountRequestForm {...props}/>,
            handleSubmit: this.props.handleSubmit,
            validation: validation,
            initialValues: {
                referral: urlSearchParams.get('key') || '',
                referrer: document.referrer || '',
            },
            mode: 'none',
            loading: false,
            submitI18n: 'request-account',
            hideFooterOnPristine: true,
            useCenterventionLayout: true,
        };

        return <FormPage pageProps={pageProps} formProps={formProps}/>;
    }
}

export default withFormPageDefault<FormValues>(withRouter(AccountRequest), () => ({
    endpoint: endpoints.app.accountRequest,
    method: 'post',
    validation: validation,
}));
