import { intl } from '../../../../i18n';
import optionValues, { Option } from './types';
import { programs } from '../../../../programs';

const grade = (longK = false): Option[] => {
    return [
        { value: -1, label: intl.formatMessage({ id: longK ? 'options.grade.pre-kindergarten' : 'options.grade.p' }) },
        { value: 0, label: intl.formatMessage({ id: longK ? 'options.grade.kindergarten' : 'options.grade.k' }) },
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
        { value: 11, label: '11' },
        { value: 12, label: '12' },
    ];
};

const accountSourceStatus = (): Option[] => {
    return [
        { value: optionValues.STATUS_NOT_ACTIVATED, label: intl.formatMessage({ id: 'options.status.not-activated' }) },
        { value: optionValues.STATUS_NOT_VERIFIED, label: intl.formatMessage({ id: 'options.status.not-verified' }) },
        { value: optionValues.STATUS_ON_HOLD, label: intl.formatMessage({ id: 'options.status.on-hold' }) },
        { value: optionValues.STATUS_VERIFIED, label: intl.formatMessage({ id: 'options.status.verified' }) },
        { value: optionValues.STATUS_DEACTIVATED, label: intl.formatMessage({ id: 'options.status.deactivated' }) },
    ];
};

const accountSourceStatusWithoutDeactivated = (): Option[] => {
    return [
        { value: 'without-deactivated', label: intl.formatMessage({ id: 'options.status.without-deactivated' }) },
    ];
};

const accountSource = (): Option[] => {
    return [
        { value: optionValues.SOURCE_FORM, label: intl.formatMessage({ id: `options.account-source.${optionValues.SOURCE_FORM}` }) },
        { value: optionValues.SOURCE_INVITE, label: intl.formatMessage({ id: `options.account-source.${optionValues.SOURCE_INVITE}` }) },
        { value: optionValues.SOURCE_ADMIN, label: intl.formatMessage({ id: `options.account-source.${optionValues.SOURCE_ADMIN}` }) },
        { value: optionValues.SOURCE_UBER, label: intl.formatMessage({ id: `options.account-source.${optionValues.SOURCE_UBER}` }) },
    ];
};

const accountSourceReferral = (): Option[] => {
    return [
        { value: optionValues.SOURCE_INVITE, label: intl.formatMessage({ id: `options.account-source.${optionValues.SOURCE_INVITE}` }) },
        { value: optionValues.SOURCE_ADMIN, label: intl.formatMessage({ id: `options.account-source.${optionValues.SOURCE_ADMIN}` }) },
    ];
};

const groupType = (): Option[] => {
    return [
        { value: optionValues.DISTRICT, label: intl.formatMessage({ id: 'options.group-type.district' }) },
        { value: optionValues.SCHOOL, label: intl.formatMessage({ id: 'options.group-type.school' }) },
    ];
};

const ecommerceIssuer = (): Option[] => {
    return [
        { value: optionValues.ISSUER_USER, label: intl.formatMessage({ id: `options.${optionValues.ISSUER_USER}` }) },
        { value: optionValues.ISSUER_STRIPE, label: intl.formatMessage({ id: `options.${optionValues.ISSUER_STRIPE}` }) },
        { value: optionValues.ISSUER_ACCOUNT_ACTIVATION, label: intl.formatMessage({ id: `options.${optionValues.ISSUER_ACCOUNT_ACTIVATION}` }) },
        { value: optionValues.ISSUER_ACCOUNT_VERIFICATION, label: intl.formatMessage({ id: `options.${optionValues.ISSUER_ACCOUNT_VERIFICATION}` }) },
        { value: optionValues.ISSUER_INTERNAL_SCRIPT, label: intl.formatMessage({ id: `options.${optionValues.ISSUER_INTERNAL_SCRIPT}` }) },
    ];
};

const purchaseIssuer = (): Option[] => {
    return [
        { value: optionValues.ISSUER_USER, label: intl.formatMessage({ id: `options.${optionValues.ISSUER_USER}` }) },
        { value: optionValues.ISSUER_STRIPE, label: intl.formatMessage({ id: `options.${optionValues.ISSUER_STRIPE}` }) },
        { value: optionValues.ISSUER_INTERNAL_SCRIPT, label: intl.formatMessage({ id: `options.${optionValues.ISSUER_INTERNAL_SCRIPT}` }) },
    ];
};

const plan = (): Option[] => {
    return [
        { value: optionValues.PLAN_FREE_TRIAL, label: intl.formatMessage({ id: `options.${optionValues.PLAN_FREE_TRIAL}` }) },
        { value: optionValues.PLAN_INDIVIDUAL, label: intl.formatMessage({ id: `options.${optionValues.PLAN_INDIVIDUAL}` }) },
        { value: optionValues.PLAN_SITE, label: intl.formatMessage({ id: `options.${optionValues.PLAN_SITE}` }) },
    ];
};

const ecommerceChangeType = (): Option[] => {
    return [
        { value: optionValues.ECOMMERCE_CHANGE_TYPE_CHANGE, label: intl.formatMessage({ id: `options.${optionValues.ECOMMERCE_CHANGE_TYPE_CHANGE}` }) },
        { value: optionValues.ECOMMERCE_CHANGE_TYPE_ASSIGN, label: intl.formatMessage({ id: `options.${optionValues.ECOMMERCE_CHANGE_TYPE_ASSIGN}` }) },
        { value: optionValues.ECOMMERCE_CHANGE_TYPE_ROLLBACK, label: intl.formatMessage({ id: `options.${optionValues.ECOMMERCE_CHANGE_TYPE_ROLLBACK}` }) },
        { value: optionValues.ECOMMERCE_CHANGE_TYPE_TRANSFER, label: intl.formatMessage({ id: `options.${optionValues.ECOMMERCE_CHANGE_TYPE_TRANSFER}` }) },
        { value: optionValues.ECOMMERCE_CHANGE_TYPE_GIFT, label: intl.formatMessage({ id: `options.${optionValues.ECOMMERCE_CHANGE_TYPE_GIFT}` }) },
    ];
};

const activityChangeType = (): Option[] => {
    return [
        { value: optionValues.ACTIVITY_ADD_EDUCATOR, label: intl.formatMessage({ id: `options.${optionValues.ACTIVITY_ADD_EDUCATOR}` }) },
        { value: optionValues.ACTIVITY_EDUCATOR_DEACTIVATE, label: intl.formatMessage({ id: `options.${optionValues.ACTIVITY_EDUCATOR_DEACTIVATE}` }) },
        { value: optionValues.ACTIVITY_STUDENT_MASS_TRANSFER_TO_ANOTHER_EDUCATOR, label: intl.formatMessage({ id: `options.${optionValues.ACTIVITY_STUDENT_MASS_TRANSFER_TO_ANOTHER_EDUCATOR}` }) },
        { value: optionValues.ACTIVITY_SITE_LICENSE_GRANT_ACCESS, label: intl.formatMessage({ id: `options.${optionValues.ACTIVITY_SITE_LICENSE_GRANT_ACCESS}` }) },
        { value: optionValues.ACTIVITY_SITE_LICENSE_REVOKE_ACCESS, label: intl.formatMessage({ id: `options.${optionValues.ACTIVITY_SITE_LICENSE_REVOKE_ACCESS}` }) },
    ];
};

const purchaseChangeType = (): Option[] => {
    return [
        { value: optionValues.ECOMMERCE_CHANGE_TYPE_PLAN_CHANGE, label: intl.formatMessage({ id: `options.${optionValues.ECOMMERCE_CHANGE_TYPE_PLAN_CHANGE}` }) },
        { value: optionValues.ECOMMERCE_CHANGE_TYPE_PO, label: intl.formatMessage({ id: `options.${optionValues.ECOMMERCE_CHANGE_TYPE_PO}` }) },
        { value: optionValues.ECOMMERCE_CHANGE_TYPE_QUOTE, label: intl.formatMessage({ id: `options.${optionValues.ECOMMERCE_CHANGE_TYPE_QUOTE}` }) },
    ];
};

const allPrograms = (): Option[] => programs.all().map(program => ({ value: program, label: programs.title(program) }));

const allActivePrograms = (): Option[] => {
    return [...allPrograms(), { value: 'cdc', label: intl.formatMessage({ id: 'options.cdc' }) }];
}

const myRole = (): Option[] => {
    return [
        { value: optionValues.MY_ROLE_EDUCATOR, label: intl.formatMessage({ id: `options.${optionValues.MY_ROLE_EDUCATOR}` }) },
        { value: optionValues.MY_ROLE_ADMINISTRATOR, label: intl.formatMessage({ id: `options.${optionValues.MY_ROLE_ADMINISTRATOR}` }) },
        { value: optionValues.MY_ROLE_CLINICIAN, label: intl.formatMessage({ id: `options.${optionValues.MY_ROLE_CLINICIAN}` }) },
        { value: optionValues.MY_ROLE_HOME, label: intl.formatMessage({ id: `options.${optionValues.MY_ROLE_HOME}` }) },
        { value: optionValues.MY_ROLE_STUDENT, label: intl.formatMessage({ id: `options.${optionValues.MY_ROLE_STUDENT}` }) },
        { value: optionValues.MY_ROLE_OTHER, label: intl.formatMessage({ id: `options.${optionValues.MY_ROLE_OTHER}` }) },
    ];
};

const yesNo = (): Option[] => {
    return [
        { value: 1, label: intl.formatMessage({ id: 'options.yes' }) },
        { value: 0, label: intl.formatMessage({ id: 'options.no' }) },
    ];
};

const activeInactive = (): Option[] => {
    return [
        { value: 1, label: intl.formatMessage({ id: 'options.active' }) },
        { value: 0, label: intl.formatMessage({ id: 'options.inactive' }) },
    ];
};

const emotionCheckIn = (): Option[] => {
    return [
        { value: optionValues.EMOTION_CHECK_IN_MAD, label: intl.formatMessage({ id: `options.${optionValues.EMOTION_CHECK_IN_MAD}` }) },
        { value: optionValues.EMOTION_CHECK_IN_SAD, label: intl.formatMessage({ id: `options.${optionValues.EMOTION_CHECK_IN_SAD}` }) },
        { value: optionValues.EMOTION_CHECK_IN_WORRIED, label: intl.formatMessage({ id: `options.${optionValues.EMOTION_CHECK_IN_WORRIED}` }) },
        { value: optionValues.EMOTION_CHECK_IN_OKAY, label: intl.formatMessage({ id: `options.${optionValues.EMOTION_CHECK_IN_OKAY}` }) },
        { value: optionValues.EMOTION_CHECK_IN_HAPPY, label: intl.formatMessage({ id: `options.${optionValues.EMOTION_CHECK_IN_HAPPY}` }) },
    ];
};

const siteTier = (): Option[] => {
    return [
        { value: 0, label: intl.formatMessage({ id: 'options.site-tier.custom' }) },
        { value: 50, label: intl.formatMessage({ id: 'options.site-tier.up-to' }, { quantity: 50 }) },
        { value: 100, label: intl.formatMessage({ id: 'options.site-tier.up-to' }, { quantity: 100 }) },
        { value: 250, label: intl.formatMessage({ id: 'options.site-tier.up-to' }, { quantity: 250 }) },
        { value: 500, label: intl.formatMessage({ id: 'options.site-tier.up-to' }, { quantity: 500 }) },
        { value: 1000, label: intl.formatMessage({ id: 'options.site-tier.up-to' }, { quantity: 1000 }) },
        { value: 2000, label: intl.formatMessage({ id: 'options.site-tier.up-to' }, { quantity: 2000 }) },
    ];
};

const bulkUploadRowStatus = (): Option[] => {
    return [
        { value: optionValues.STATUS_PENDING, label: intl.formatMessage({ id: `options.status.${optionValues.STATUS_PENDING}` }) },
        { value: optionValues.STATUS_CREATED, label: intl.formatMessage({ id: `options.status.${optionValues.STATUS_CREATED}` }) },
        { value: optionValues.STATUS_UPDATED, label: intl.formatMessage({ id: `options.status.${optionValues.STATUS_UPDATED}` }) },
        { value: optionValues.STATUS_FAILED, label: intl.formatMessage({ id: `options.status.${optionValues.STATUS_FAILED}` }) },
    ];
};

const groupDataDefaultFilter = (): Option[] => {
    return [
        { value: optionValues.GROUP_DATA_EMERGING, label: intl.formatMessage({ id: `options.group-data.emerging` }) },
        { value: optionValues.GROUP_DATA_IMPROVING, label: intl.formatMessage({ id: `options.group-data.improving` }) },
        { value: optionValues.GROUP_DATA_PROFICIENT, label: intl.formatMessage({ id: `options.group-data.proficient` }) },
    ];
};

const resourceType = (): Option[] => {
    return [
        { value: optionValues.RESOURCE_TYPE_WORKSHEET, label: intl.formatMessage({ id: `options.${optionValues.RESOURCE_TYPE_WORKSHEET}` }) },
        { value: optionValues.RESOURCE_TYPE_VIDEO, label: intl.formatMessage({ id: `options.${optionValues.RESOURCE_TYPE_VIDEO}` }) },
        { value: optionValues.RESOURCE_TYPE_ACTIVITY, label: intl.formatMessage({ id: `options.${optionValues.RESOURCE_TYPE_ACTIVITY}` }) },
        { value: optionValues.RESOURCE_TYPE_QUIZ, label: intl.formatMessage({ id: `options.${optionValues.RESOURCE_TYPE_QUIZ}` }) },
        { value: optionValues.RESOURCE_TYPE_ARTICLE, label: intl.formatMessage({ id: `options.${optionValues.RESOURCE_TYPE_ARTICLE}` }) },
    ];
};

const usage = (): Option[] => {
    return [
        { value: optionValues.USAGE_GROUP, label: intl.formatMessage({ id: `options.${optionValues.USAGE_GROUP}` }) },
        { value: optionValues.USAGE_INDIVIDUAL, label: intl.formatMessage({ id: `options.${optionValues.USAGE_INDIVIDUAL}` }) },
    ];
};

const gradeLevels = (): Option[] => {
    return [
        { value: optionValues.GRADE_LEVEL_ELEMENTARY, label: intl.formatMessage({ id: `options.elementary` }) },
        { value: optionValues.GRADE_LEVEL_MIDDLE, label: intl.formatMessage({ id: `options.middle` }) },
    ];
};

const skills = (): Option[] => {
    return [
        { value: optionValues.SKILLS_IC, label: intl.formatMessage({ id: `options.ic` }) },
        { value: optionValues.SKILLS_COM, label: intl.formatMessage({ id: `options.com` }) },
        { value: optionValues.SKILLS_COOP, label: intl.formatMessage({ id: `options.coop` }) },
        { value: optionValues.SKILLS_INI, label: intl.formatMessage({ id: `options.ini` }) },
        { value: optionValues.SKILLS_EMP, label: intl.formatMessage({ id: `options.emp` }) },
        { value: optionValues.SKILLS_ER, label: intl.formatMessage({ id: `options.er` }) },
    ];
};

const dayWeekMonth = (): Option[] => {
    return [
        { value: optionValues.DAY, label: intl.formatMessage({ id: `options.${optionValues.DAY}` }) },
        { value: optionValues.WEEK, label: intl.formatMessage({ id: `options.${optionValues.WEEK}` }) },
        { value: optionValues.MONTH, label: intl.formatMessage({ id: `options.${optionValues.MONTH}` }) },
        { value: optionValues.CUSTOM, label: intl.formatMessage({ id: `options.${optionValues.CUSTOM}` }) },
    ];
};

export default {
    grade,
    accountSourceStatus,
    accountSourceStatusWithoutDeactivated,
    accountSource,
    accountSourceReferral,
    groupType,
    ecommerceIssuer,
    purchaseIssuer,
    plan,
    ecommerceChangeType,
    activityChangeType,
    purchaseChangeType,
    allPrograms,
    allActivePrograms,
    myRole,
    yesNo,
    activeInactive,
    emotionCheckIn,
    siteTier,
    bulkUploadRowStatus,
    groupDataDefaultFilter,
    resourceType,
    usage,
    gradeLevels,
    skills,
    dayWeekMonth,
};
