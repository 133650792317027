import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import { useField } from 'react-final-form';
import Hidden from '@material-ui/core/Hidden';
import { FormattedMessage } from 'react-intl';
import { FormValues } from './types';
import Body from '../../../dialog/Body';
import { FormProps } from '../../../form/types';
import InputText from '../../../form/InputText';
import InputHidden from '../../../form/InputHidden';
import InputSwitch from '../../../form/InputSwitch';
import InputDropdown from '../../../form/InputDropdown';
import { options, optionValues } from '../../../form/select';
import styles from './_account.module.sass';

const AccountRequestForm: FunctionComponent<FormProps<FormValues>> = () => {
    const myRole = useField('my_role').input.value;
    const isRoleSelected = !! myRole;
    const isSchool = isRoleSelected && myRole !== optionValues.MY_ROLE_STUDENT && myRole !== optionValues.MY_ROLE_HOME;

    const innerForm = <>
        {isSchool && <>
            <Grid item xs={12}>
                <InputText name="my_role_description"/>
            </Grid>
        </>}

        {isRoleSelected && <>
            <Grid item xs={12} md={6}>
                <InputText name="first_name"/>
            </Grid>
            <Grid item xs={12} md={6}>
                <InputText name="last_name"/>
            </Grid>
            <Grid item xs={12}>
                <InputText name="email" i18n={isSchool ? 'school-email' : 'email'}/>
            </Grid>
        </>}

        {isSchool && <>
            <Grid item xs={12}>
                <InputText name="district"/>
            </Grid>
            <Grid item xs={12}>
                <InputText name="school"/>
            </Grid>
            <Grid item xs={12} className={styles.hideX}>
                <InputText name="programs_evaluation_goal"/>
            </Grid>
            <Grid item xs={12} className={styles.left}>
                <InputSwitch name="i_agree_to_one_student" i18n=""/>
            </Grid>
        </>}

        <InputHidden name="referral"/>
        <InputHidden name="referrer"/>
    </>;

    if (isSchool) {
        return <>
            <Body>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <InputDropdown name="my_role" options={options.myRole()}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={7}>
                                <Grid container spacing={3}>
                                    {innerForm}
                                </Grid>
                            </Grid>
                            <Hidden smDown>
                                <Grid item md={5}>
                                    <section className={styles.accountRequestDescription}>
                                        <h3><FormattedMessage id="form.account-request.header"/>:</h3>
                                        <ul>
                                            <li><FormattedMessage id="form.account-request.li.1"/></li>
                                            <li><FormattedMessage id="form.account-request.li.2"/></li>
                                            <li><FormattedMessage id="form.account-request.li.3"/></li>
                                            <li><FormattedMessage id="form.account-request.li.4"/></li>
                                        </ul>
                                    </section>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
            </Body>
        </>;
    }

    return <>
        <Body>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <InputDropdown name="my_role" options={options.myRole()}/>
                </Grid>

                {innerForm}
            </Grid>
        </Body>
    </>;
};

export default AccountRequestForm;
