import { createReducer } from '../../utils';
import types, {
    UIState,
    ConfettiShowAction,
    SidebarToggleAction,
    TabsActivateTabAction,
    TabsActivateGroupAction,
} from "./types";

const initialState: UIState = {
    sidebar: {
        isCollapsed: false,
        uberIsOpen: true,
        studentsIsOpen: true,
        accountSettingsIsOpen: true,
        uberToolsIsOpen: false,
    },
    tabs: {},
    lastTab: undefined,
    confetti: false,
};

const sidebarToggle = (state: UIState, action: SidebarToggleAction): UIState => {
    const { sidebar } = state;

    return { ...state, sidebar: { ...sidebar, [action.payload]: ! sidebar[action.payload] } };
};

const tabsActivateGroup = (state: UIState, action: TabsActivateGroupAction): UIState => {
    const { tabs } = state;
    const currentGroup = (tabs[action.payload.id] || []).find(group => group.group === action.payload.group);
    const groups = (tabs[action.payload.id] || []).filter(group => group.group !== action.payload.group);

    const newGroups = groups.reduce((result: any, current: any) => {
        result.push(current);
        return result;
    }, [{ group: action.payload.group, tab: currentGroup?.tab || action.payload.defaultTab }]);

    return { ...state, tabs: { ...tabs, [action.payload.id]: newGroups }, lastTab: newGroups[0] };
};

const tabsActivateTab = (state: UIState, action: TabsActivateTabAction): UIState => {
    const { tabs } = state;
    const groups = (tabs[action.payload.id] || []).filter(group => group.group !== action.payload.group);

    const newGroups = groups.reduce((result: any, current: any ) => {
        result.push(current);
        return result;
    }, [{ group: action.payload.group, tab: action.payload.tab }]);

    return { ...state, tabs: { ...tabs, [action.payload.id]: newGroups }, lastTab: newGroups[0] };
};

const signOut = (state: UIState): UIState => {
    return {
        sidebar: { ...state.sidebar, uberToolsIsOpen: false },
        tabs: {},
        lastTab: undefined,
        confetti: false,
    };
};

const confettiShow = (state: UIState, action: ConfettiShowAction): UIState => {
    return { ...state, confetti: action.payload.show };
};

const reducers = createReducer(initialState, {
    [types.SIDEBAR_TOGGLE]: sidebarToggle,
    [types.TABS_ACTIVATE_GROUP]: tabsActivateGroup,
    [types.TABS_ACTIVATE_TAB]: tabsActivateTab,
    [types.SIGN_OUT]: signOut,
    [types.CONFETTI_SHOW]: confettiShow,
} as any);

export default reducers;
