import { GradeLevel, ResourceType, Skills, Usage } from '../../actions/uber/lessons/types';

const DISTRICT = 'district';
const SCHOOL = 'school';
const SOURCE_FORM = 'form';
const SOURCE_ADMIN = 'admin';
const SOURCE_INVITE = 'invite';
const SOURCE_UBER = 'uber';
const STATUS_NOT_ACTIVATED = 'not-activated';
const STATUS_NOT_VERIFIED = 'not-verified';
const STATUS_ON_HOLD = 'on-hold';
const STATUS_VERIFIED = 'verified';
const STATUS_DEACTIVATED = 'deactivated';
const STATUS_PENDING = 'pending';
const STATUS_CREATED = 'created';
const STATUS_UPDATED = 'updated';
const STATUS_FAILED = 'failed';
const MY_ROLE_EDUCATOR = 'educator';
const MY_ROLE_ADMINISTRATOR = 'administrator';
const MY_ROLE_CLINICIAN = 'clinician';
const MY_ROLE_HOME = 'home';
const MY_ROLE_STUDENT = 'student';
const MY_ROLE_OTHER = 'other';
const ISSUER_USER = 'fortress_user';
const ISSUER_STRIPE = 'stripe';
const ISSUER_ACCOUNT_ACTIVATION = 'account_activation';
const ISSUER_ACCOUNT_VERIFICATION = 'account_verification';
const ISSUER_INTERNAL_SCRIPT = 'internal_script';
const PLAN_FREE_TRIAL = 'free-trial';
const PLAN_INDIVIDUAL = 'individual';
const PLAN_SITE = 'site';
const ECOMMERCE_CHANGE_TYPE_PLAN_CHANGE = 'purchase-created,purchase-updated,give-for-free-created,give-for-free-updated';
const ECOMMERCE_CHANGE_TYPE_PO = 'purchase-order-individual-created,purchase-order-site-created,purchase-created-for-distribution,purchase-updated-for-distribution';
const ECOMMERCE_CHANGE_TYPE_QUOTE = 'request-quote-individual-created,request-quote-site-created,quote-individual-created,quote-site-created';
const ECOMMERCE_CHANGE_TYPE_CHANGE = `${ECOMMERCE_CHANGE_TYPE_PLAN_CHANGE},${ECOMMERCE_CHANGE_TYPE_PO},${ECOMMERCE_CHANGE_TYPE_QUOTE}`;
const ECOMMERCE_CHANGE_TYPE_ASSIGN = 'use-product-assigned,use-product-reassigned';
const ECOMMERCE_CHANGE_TYPE_ROLLBACK = 'use-product-rollback-unassigned';
const ECOMMERCE_CHANGE_TYPE_TRANSFER = 'purchase-created-for-transfer,purchase-updated-for-transfer';
const ECOMMERCE_CHANGE_TYPE_GIFT = 'purchase-gift-invitee-given,purchase-gift-invitor-given';
const EMOTION_CHECK_IN_MAD = 'mad';
const EMOTION_CHECK_IN_SAD = 'sad';
const EMOTION_CHECK_IN_WORRIED = 'worried';
const EMOTION_CHECK_IN_OKAY = 'okay';
const EMOTION_CHECK_IN_HAPPY = 'happy';
const ACTIVITY_ADD_EDUCATOR = 'add-educator';
const ACTIVITY_EDUCATOR_DEACTIVATE = 'deactivate-educator';
const ACTIVITY_STUDENT_MASS_TRANSFER_TO_ANOTHER_EDUCATOR = 'student-mass-transfer-to-another-educator';
const ACTIVITY_SITE_LICENSE_GRANT_ACCESS = 'site-license-grant-access';
const ACTIVITY_SITE_LICENSE_REVOKE_ACCESS = 'site-license-revoke-access';
const GROUP_DATA_EMERGING = '0';
const GROUP_DATA_IMPROVING = '1';
const GROUP_DATA_PROFICIENT = '2';
const GRADE_LEVEL_ELEMENTARY = GradeLevel.Elementary;
const GRADE_LEVEL_MIDDLE = GradeLevel.Middle;
const SKILLS_IC = Skills.IC;
const SKILLS_COM = Skills.COM;
const SKILLS_COOP = Skills.COOP;
const SKILLS_INI = Skills.INI;
const SKILLS_EMP = Skills.EMP;
const SKILLS_ER = Skills.ER;
const RESOURCE_TYPE_WORKSHEET = ResourceType.Worksheet;
const RESOURCE_TYPE_VIDEO = ResourceType.Video;
const RESOURCE_TYPE_ACTIVITY = ResourceType.Activity;
const RESOURCE_TYPE_QUIZ = ResourceType.Quiz;
const RESOURCE_TYPE_ARTICLE = ResourceType.Article;
const USAGE_GROUP = Usage.Group;
const USAGE_INDIVIDUAL = Usage.Individual;
const DAY = 'day';
const WEEK = 'week';
const MONTH = 'month';
const CUSTOM = 'custom';

export default {
    DISTRICT,
    SCHOOL,
    SOURCE_FORM,
    SOURCE_ADMIN,
    SOURCE_INVITE,
    SOURCE_UBER,
    STATUS_NOT_ACTIVATED,
    STATUS_NOT_VERIFIED,
    STATUS_ON_HOLD,
    STATUS_VERIFIED,
    STATUS_DEACTIVATED,
    STATUS_PENDING,
    STATUS_CREATED,
    STATUS_UPDATED,
    STATUS_FAILED,
    MY_ROLE_EDUCATOR,
    MY_ROLE_ADMINISTRATOR,
    MY_ROLE_CLINICIAN,
    MY_ROLE_HOME,
    MY_ROLE_STUDENT,
    MY_ROLE_OTHER,
    ISSUER_USER,
    ISSUER_STRIPE,
    ISSUER_ACCOUNT_ACTIVATION,
    ISSUER_ACCOUNT_VERIFICATION,
    ISSUER_INTERNAL_SCRIPT,
    PLAN_FREE_TRIAL,
    PLAN_INDIVIDUAL,
    PLAN_SITE,
    ECOMMERCE_CHANGE_TYPE_PLAN_CHANGE,
    ECOMMERCE_CHANGE_TYPE_PO,
    ECOMMERCE_CHANGE_TYPE_QUOTE,
    ECOMMERCE_CHANGE_TYPE_CHANGE,
    ECOMMERCE_CHANGE_TYPE_ASSIGN,
    ECOMMERCE_CHANGE_TYPE_ROLLBACK,
    ECOMMERCE_CHANGE_TYPE_TRANSFER,
    ECOMMERCE_CHANGE_TYPE_GIFT,
    EMOTION_CHECK_IN_MAD,
    EMOTION_CHECK_IN_SAD,
    EMOTION_CHECK_IN_WORRIED,
    EMOTION_CHECK_IN_OKAY,
    EMOTION_CHECK_IN_HAPPY,
    ACTIVITY_ADD_EDUCATOR,
    ACTIVITY_EDUCATOR_DEACTIVATE,
    ACTIVITY_STUDENT_MASS_TRANSFER_TO_ANOTHER_EDUCATOR,
    ACTIVITY_SITE_LICENSE_GRANT_ACCESS,
    ACTIVITY_SITE_LICENSE_REVOKE_ACCESS,
    GROUP_DATA_EMERGING,
    GROUP_DATA_IMPROVING,
    GROUP_DATA_PROFICIENT,
    GRADE_LEVEL_ELEMENTARY,
    GRADE_LEVEL_MIDDLE,
    SKILLS_IC,
    SKILLS_COM,
    SKILLS_COOP,
    SKILLS_INI,
    SKILLS_EMP,
    SKILLS_ER,
    RESOURCE_TYPE_WORKSHEET,
    RESOURCE_TYPE_VIDEO,
    RESOURCE_TYPE_ACTIVITY,
    RESOURCE_TYPE_QUIZ,
    RESOURCE_TYPE_ARTICLE,
    USAGE_GROUP,
    USAGE_INDIVIDUAL,
    DAY,
    WEEK,
    MONTH,
    CUSTOM,
};

export type Option = {
    value: string | number,
    label: string,
};
